export const environment = {
    name: 'eveara',
    appName: 'EVEARA',
    production: true,
    authUrl: 'https://auth.skytracks.io',
    apiUrl: 'https://api.skytracks.io',
    wpUrl: 'NA',
    studioUrl: 'https://cocreate.eveara.com',
    playerUrl: 'https://player.eveara.com',
    intercomAppId: 'NA',
    googleAnalyticsId: 'NA',
    musicteamUrl: 'https://app.musicteam.com',
    chargebeeSite: 'skytracks'
};
